import React from "react"
import styled from '@emotion/styled'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
});

const VerticalSection = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
)

export default VerticalSection
