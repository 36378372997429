import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalSection from "../../components/vertical-section"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "mini-byt/mini-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "mini-byt/mini-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "mini-byt/mini-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "mini-byt/mini-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "mini-byt/mini-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "mini-byt/mini-6.jpg" }) { ...fluidImage }
    photo7: file(relativePath: { eq: "mini-byt/mini-7.jpg" }) { ...fluidImage }
    photo8: file(relativePath: { eq: "mini-byt/mini-8.jpg" }) { ...fluidImage }
  }
`

const MinibytPage = ({ data }) => (
  <Layout title="Mini byt">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalSection>
      <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
      <VerticalText>
        <Heading>Byt 16 m<sup>2</sup>, Praha 7, 2014</Heading>
        <Text>
          Zadáním bylo vytvořit plnohodnotnou bytovou jednotku na ploše 16 m<sup>2</sup> pro mladý pár. Snahou bylo se vyvarovat velkému množství rozkládácích prvků, které se často využívají v malých bytech. Půdorysně má místnost tvar písmene L se světlou výškou 4,1 m. Do menší části u vstupních dveří je umístěn multifunkční vestavěný blok, který dělí byt na dvě části. V tomto bloku je umístěn vstup a koupelna obsahující sprchový kout, umyvadlo a toaletu. Pro úsporu místa jsou místo zděných příček navrženy skleněné posuvné panely, které prostor opticky zvětšují. Sníženým průchodem, v němž jsou umístěny šatní skříně, se vstupuje do obytné části spojené s kuchyní. I přes malý rozměr bytu je kuchyň poměrně velká a součástí je barové sezení umístěné naproti oknu. Pod mlynářskými schody, vedoucími do patra, jsou umístěny úložné prostory a vyklápěcí stůl. Místo pro spaní se nachází v patře a prostor pod postelí zvětšuje světlou výšku koupelny a chodby. Postel je vestavěnou součástí celého bloku, má rozměry standardního dvojlůžka. V nice vedle postele je malá pracovna. Součástí ložnice je i další uzaviratelný prostor kde se nachází i pračka. Vzhledem k velikosti bytu byly zvoleny především světlé materiály. Kontrast k nim představuje vstupní část s WC a koupelnou v sytě červené barvě. Povrch multifunkčního bloku tvoří v obytné části stavební překližka. Cihlová stěna byla zachována i se všemi svými nedokonalostmi a decetně oživuje celý prostor.
        </Text>
      </VerticalText>
    </VerticalSection>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo4.childImageSharp.fluid} />
    <VerticalImage image={data.photo5.childImageSharp.fluid} />
    <HorizontalImage marginTop borderBottom borderTop image={data.photo6.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo7.childImageSharp.fluid} />
    <VerticalImage image={data.photo8.childImageSharp.fluid} />
  </Layout>
)

export default MinibytPage
